<template>
  <div class="relative" :id="randomId">
    <a-upload
      v-if="multiple"
      ref="multipleUpload"
      list-type="picture-card"
      :file-list="fileList"
      multiple
      :customRequest="uploadOSSImage"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div>
        <a-icon type="plus" />
        <div class="ant-upload-text">{{ uploadTxt || "上传" }}</div>
      </div>
    </a-upload>
    <a-upload
      v-else
      list-type="picture-card"
      :customRequest="uploadOSSImage"
      :show-upload-list="false"
      :before-upload="beforeUpload"
      @preview="handlePreview"
    >
      <div class="relative" v-if="imgUrl">
        <img :src="imgUrl" alt style="width:100px;" />
      </div>
      <div v-if="!imgUrl || multiple">
        <a-icon type="plus" />
        <div class="ant-upload-text">{{ uploadTxt || "上传" }}</div>
      </div>
    </a-upload>
    <a-icon
      v-if="imgUrl && !multiple"
      type="close-circle"
      class="icon-remove"
      @click="handlerRemove"
    />
    <a-icon
      v-if="!imgUrl && isDelete"
      type="close-circle"
      class="icon-normal-remove"
      @click="handlerRemove"
    />
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { getPolicySign } from "@/api/util";
import axios from "@/utils/ajax";

import config from "@/utils/config.js";

import Sortable from "sortablejs";

export default {
  props: {
    // 1-列表横图，2-列表方图，3-详情横图，4-详情方图，5-详情描述图
    type: {
      type: String,
      default: "",
    },
    // 是否多文件上传
    multiple: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: "",
    },
    uploadTxt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      flag: true,
      previewVisible: false,
      previewImage: "",
      // HOST_IMG_URL: config.HOST_IMG_URL,
      imgUrl: "",
      fileList: [],
      successList: [],
      randomId: "upload" + Date.now(),
    };
  },
  mounted() {
    if (!this.multiple) {
      if (this.value) {
        this.imgUrl = config.HOST_IMG_URL + this.value;
      }
    } else {
      if (this.value.length > 0) {
        this.fileList = this.value.map((item, i) => {
          let obj = {
            uid: i,
            name: item,
            status: "done",
            url: config.HOST_IMG_URL + item,
          };
          if (item.response) {
            obj = {
              ...item,
              ...{
                uid: i,
                name: item.response.name,
                status: "done",
                url: item.response.url,
              },
            };
          }
          return obj;
        });
      }
    }
  },
  methods: {
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector(
        `#${this.randomId} .ant-upload-picture-card-wrapper .ant-upload-list-picture-card`
      );

      const _this = this;
      Sortable.create(tbody, {
        group: ".ant-upload-list-picture-card-container",
        handle: ".ant-upload-list-picture-card-container",
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.fileList.splice(oldIndex, 1)[0];
          _this.fileList.splice(newIndex, 0, currRow);
          const newArr = [];
          _this.fileList.map((item) => {
            if (item.response) {
              newArr.push(item.response.name);
            } else {
              newArr.push(item.name);
            }
          });
          _this.$emit("update:value", newArr);
          _this.$emit("getList", newArr);
        },
      });
    },

    async uploadOSSImage(file) {
      const { data } = await getPolicySign({ type: this.type });
      var extension = "";
      if (/.*(\.[^\\.]*)/.test(file.file.name)) {
        extension = RegExp.$1;
      }
      // file.onProgress()

      const filename = data.filename;
      const osspath = data.policy.dir + filename + extension;

      let formData = new FormData();
      formData.append("key", osspath);
      formData.append("OSSAccessKeyId", data.policy.accessid);
      formData.append("policy", data.policy.policy);
      formData.append("signature", data.policy.signature);
      formData.append("callback", data.policy.callback);
      formData.append("success_action_status", 200);
      formData.append("file", file.file);

      axios({
        url: data.policy.host,
        method: "post",
        processData: false,
        data: formData,
      })
        .then((res) => {
          if (res.data.Status == "Ok") {
            this.imgUrl = data.policy.host + "/" + osspath;

            if (this.multiple) {
              file.onSuccess({
                name: osspath,
                url: data.policy.host + "/" + osspath,
              }); //上传成功
              // const newArr = []
              // this.fileList.map((item) => {
              //   if (item.response) {
              //     newArr.push(item.response.name)
              //   }
              // })
              // this.$emit("update:value", this.successList)
            } else {
              this.$emit("update:value", osspath);
            }
          } else {
            file.onError(); //上传失败
          }
        })
        .catch(() => {
          //
        });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 删除
    handlerRemove() {
      this.imgUrl = "";
      this.$emit("update:value", "");
      this.$emit("remove");
    },
    handleChange(info) {
      const status = info.file.status;

      // info.file.status = 'done'
      if (status == "uploading" || status == "removed") {
        this.fileList = info.fileList;
      }

      if (status === "removed") {
        const newArr = [];
        this.fileList.map((item) => {
          if (item.response) {
            newArr.push(item.response.name);
          } else {
            newArr.push(item.name);
          }
        });
        if (info.file.response) {
          this.successList = this.successList.filter(
            (el) => el != info.file.response.name
          );
        } else {
          this.successList = this.successList.filter(
            (el) => el != info.file.name
          );
        }

        this.$emit("getList", this.successList);
      }

      if (status === "done") {
        info.file.name = info.file.response.name;
        info.file.url = info.file.response.url;

        this.successList.push(info.file.response.name);

        this.$emit("getList", this.successList);
      } else if (status === "error") {
        console.log(`${info.file.name} 文件上传失败.`);
      }
    },
    // 上传前验证图片格式
    beforeUpload(file) {
      if (
        !(
          file.type == "image/jpeg" ||
          file.type == "image/png" ||
          file.type == "image/gif"
        )
      ) {
        this.$message.error("请选择有效的图片格式");
        return false;
      }
      if (file.size / 1024 / 1024 > 5) {
        this.$message.error("图片不能大于5M");
        return false;
      }
      return true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
  watch: {
    value() {
      // 如果是多图
      if (this.flag) {
        if (!this.multiple) {
          if (this.value) {
            this.flag = false;
            this.imgUrl = config.HOST_IMG_URL + this.value;
          }
        } else {
          if (this.value.length > 0) {
            this.flag = false;
            this.value.map((item, i) => {
              let obj = {
                uid: i,
                name: item,
                status: item.status ? item.status : "done",
                url: config.HOST_IMG_URL + item,
              };
              if (item.response) {
                obj = {
                  ...item,
                  ...{
                    uid: i,
                    name: item.response.name,
                    status: item.status ? item.status : "done",
                    url: item.response.url,
                  },
                };
              }
              if (!this.successList.find((el) => el == item)) {
                this.fileList.push(obj);
                this.successList.push(item);
              }
            });
            this.$nextTick(() => {
              this.rowDrop();
            });
          }
        }
      }
    },
  },
};
</script>

<style>
.icon-remove {
  position: absolute;
  font-size: 24px;
  z-index: 9;
  top: -5px;
  left: 100px;
}
.icon-normal-remove {
  position: absolute;
  font-size: 24px;
  z-index: 9;
  top: -5px;
  left: 90px;
}
</style>

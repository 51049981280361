import ajax from '@/utils/ajax.js'

/**
 * web页广告-列表
 * @param {*} params 
 * @returns 
 */
export function getWebAdList(params) {
  return ajax.post('/EventAd/getList',params)
}

/**
 * web页广告-详情
 * @param {*} params 
 * @returns 
 */
export function getWebAdDetail(params) {
  return ajax.post('/EventAd/detail',params)
}

/**
 * web页广告-添加
 * @param {*} params 
 * @returns 
 */
export function addWebAd(params) {
  return ajax.post('/EventAd/add',params)
}

/**
 * web页广告-修改
 * @param {*} params 
 * @returns 
 */
export function updateWebAd(params) {
  return ajax.post('/EventAd/update',params)
}

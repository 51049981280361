<template>
  <a-card>
    <a-form-model ref="ruleForm"
                  :model="formState"
                  :label-col="labelCol"
                  :wrapper-col="wrapperCol">
      <a-form-model-item prop="title" class="mb-2"
                         label="页面标题" :wrapper-col="{span:14}"
                         :rules="requiredRule">
        <a-input v-model="formState.title" />
      </a-form-model-item>

      <a-form-model-item label="页面图片" class="mb-2">
        <!-- <a-checkbox v-model="hasOtherXcxDetailImage">首图跳第三方小程序</a-checkbox> -->
        <!-- <div v-if="hasOtherXcxDetailImage" class="flex items-center">
          <uploadImage uploadTxt="上传首图" type="7" :value.sync="formState.other_xcx_ad_image_path" />
          <div style="width:500px;">
            <a-form-model-item prop="other_xcx_app_id" class="mb-2" label="*小程序appid" 
                             :label-col="{span:6}" :wrapper-col="{span:18}">
              <a-input v-model="formState.other_xcx_app_id" placeholder="小程序appid" />
            </a-form-model-item>
            <a-form-model-item prop="other_xcx_path" class="mb-2" label="小程序path"
                             :label-col="{span:6}" :wrapper-col="{span:18}">
              <a-input v-model="formState.other_xcx_path" placeholder="小程序path（选填）" />
            </a-form-model-item>
          </div>
        </div> -->
        <uploadImage type="13" multiple
                     :value="ad_image_path_list"
                     @getList="(list)=>{ ad_image_path_list = list }" />
        <span class="img-tip">
          <a-button class="p-0 ml-4" type="link"
                  @click="handlerPreview">效果预览</a-button>
        </span>
      </a-form-model-item>
      
      <a-form-model-item v-if="!isDetail" style="text-align:center;">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-model-item>
    </a-form-model>

    <a-modal class="preview-class"
             title="预览"
             width="450px"
             :visible="previewVisible"
             :footer="null"
             @cancel="previewVisible = false">
      <div style="height:600px;overflow: auto;">
        <img style="width: 100%"
             v-for="(item,i) in ad_image_path_list"
             :key="i"
             :src="imgHost + item" />
      </div>
    </a-modal>

  </a-card>

</template>

<script>

import uploadImage from '@/components/upload-image'
import { requiredRule } from '@/hooks/use-form-rules'

import config from '@/utils/config'
import {
  getWebAdDetail,
  addWebAd,
  updateWebAd,
} from '@/api/web-ad'

export default {
  components: {
    uploadImage,
  },
  data () {
    return {
      requiredRule: requiredRule,

      imgHost: config.HOST_IMG_URL,
      isDetail: false,
      previewVisible: false,

      labelCol: { span: 2 },
      wrapperCol: { span: 21 },
      formState: {
        id: '',
        title: '',
        content: '', // { img_list }
      },
      hasOtherXcxDetailImage: false,
      ad_image_path_list: [], // 多图

      loading: false,
    }
  },

  created () {
    this.initData()

    if (this.$route.query.type === 'detail') {
      this.isDetail = true
    }
  },
  methods: {
    async initData () {
      if (this.$route.query.id) {
        const res = await getWebAdDetail({ id: this.$route.query.id })

        let img_list = []
        try{
          const content = JSON.parse(res.data.content)
          if(content && content.img_list){
            img_list = content.img_list
          }
        }catch(e){
          console.log("解析错误", e)
        }
        this.ad_image_path_list = img_list


        this.formState = res.data

        // 详情图-跳小程序
        // this.hasOtherXcxDetailImage = !!infos.other_xcx_ad_image_path

      }

      if (this.formState.type === 3) {
        this.goodSkuColumns = this.skuCakeColumns
      } else {
        this.goodSkuColumns = this.skuColumns
      }
    },
    // 保存
    onSubmit () {
      if(this.loading) return

      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // if(this.hasOtherXcxDetailImage){
          //   if(!this.formState.other_xcx_ad_image_path || !this.formState.other_xcx_app_id){
          //     this.$message.info('详情首图信息不完整')
          //     return
          //   }
          // }else{
          //   this.formState.other_xcx_ad_image_path = ""
          //   this.formState.other_xcx_app_id = ""
          //   this.formState.other_xcx_path = ""
          // }

          let params = { 
            ...this.formState,
            content: JSON.stringify({
              img_list: this.ad_image_path_list,
            })
          }
          console.log("params", params)

          if (this.$route.query.type == 'edit') { //如果是编辑
            this.loading = true
            updateWebAd(params).then(res => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success('修改广告页成功！')
              }
            })
          } else {
            this.loading = true
            addWebAd(params).then(res => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success('新增广告页成功！')
                this.$router.push({ name: 'web-ad' })
              }
            })
          }
        } else {
          this.$message.error("请完善必填信息后在提交")
          return false;
        }
      });
    },


    // 预览详情描述
    handlerPreview () {
      this.previewVisible = true
    }

  },

}
</script>

<style lang="less">
.sku-disable-status {
  background: rgb(205, 205, 205);
  opacity: 0.8;
  &:hover {
    background: rgb(205, 205, 205);
    opacity: 0.8;
  }
}
.img-tip {
  position: absolute;
  // width: 600px;
  margin-top: -30px;
}
</style>